import React from 'react';
import Desktop from '../../Images/DellDesktop.avif';
import Laptop from '../../Images/Think-full.png';
import CustomConfig from '../../Images/CustomConfig.jpeg';
import Onsite from '../../Images/Onsite-IT-Support.jpg';
import DTDC from '../../Images/DTDC.png';
const ITRentalPage = () => {
        return (
            <section class=" py-14 bg-white-100 font-poppins dark:bg-white-800">
                <div class="max-w-5xl px-4 py-6 mx-auto lg:py-4 md:px-6">

                    <div className='max-w-4xl mx-auto text-center'>
                        <h2 className="text-4xl font-semibold text-blue-400 mb-8">IT Rental Services</h2>
                        <span className=''>
                        Computer Care Junction, we specialize in providing high-quality computer desktop and laptop rental services to meet the diverse needs of our clients. With a focus on reliability, affordability, and customer satisfaction, we strive to be the premier choice for businesses, events, educational institutions, and individuals seeking temporary computer solutions.
                        </span>
                    </div>

                    <div className="flex">
                        <div className="relative w-1/2 md:mt-20 md:text-small font-base">
                            <p className='mt-10'>
                             <span className='text-blue-400 font-semibold'>Desktop Rental: </span> We offer a wide range of desktop computers, including PCs and Macs, equipped with the latest hardware and software to meet your specific requirements.
                            </p>
                        </div>
                        <div className="h-60 w-65 bg-gray-100 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                            <img className="-mt-10 ml-8 block w-full/6 md:h-80" src={Desktop} alt="..." />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="h-60 w-65 bg-gray-100 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                            <img className="mt-2 ml-20 block w-full/6 md:w-80" src={Laptop} alt="..." />
                        </div>
                        <div className="relative w-1/2 md:mt-20 md:text-small font-base">
                            <p className='mt-10 ml-10'>
                             <span className='text-blue-400 font-semibold'>Laptop Rental: </span> Our extensive inventory features a variety of laptops, from lightweight ultra books to powerful workstations, perfect for meetings, conferences, travel, and temporary office setups.
                            </p>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="relative w-1/2 md:mt-20 md:text-small font-base">
                            <p className='mt-10'>
                             <span className='text-blue-400 font-semibold'>Custom Configurations: </span> Tailoring our rentals to your exact needs, <br/>we provide custom configurations, including software installations, hardware upgrades, and network setups, ensuring seamless integration with your existing systems.
                            </p>
                        </div>
                        <div className="h-60 w-65 bg-gray-100 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                            <img className="-mt-10 ml-8 block w-full/6 md:h-80 p-14" src={CustomConfig} alt="..." />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="h-60 w-65 bg-gray-100 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                            <img className="mt-2 ml-20 block w-full/6 md:w-70" src={Onsite} alt="..." />
                        </div>
                        <div className="relative ml-10 w-1/2 md:mt-20 md:text-small font-base">
                            <p className='mt-10'>
                             <span className='text-blue-400 font-semibold'>On-Site Support: </span> Our team of experienced technicians is available to provide on-site support during setup, troubleshooting, and throughout the rental period, ensuring smooth operation and minimal downtime.
                            </p>
                        </div>
                    </div>

                    <div className="flex">
                        <div className="relative w-1/2 md:mt-20 md:text-small font-base">
                            <p className='mt-10 ml-10'>
                             <span className='text-blue-400 font-semibold'>Nation-wide Delivery: </span> With nationwide delivery capabilities, we can efficiently deliver and set up computer rentals at any location, saving you time and hassle.
                            </p>
                        </div>
                        <div className="h-60 w-65 bg-gray-100 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                            <span className='text-2xl ml-20 text-blue-900 font-semibold'>Anytime, Anywhere, Ontime</span>
                            <img className="mt-5 ml-10 w-full/6 md:w-80" src={DTDC} alt="..." />
                        </div>
                    </div>

                </div>
            </section >
        );
};

export default ITRentalPage;