import React from 'react';
import about from '../../Images/Image2.jpg';

const About = () => {
    return (
        <section class="py-10 lg:py-20 bg-white-100 font-poppins dark:bg-white-800">
            <div class="max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
                <div class="flex flex-wrap ">
                    <div class="w-full px-4 mb-10 lg:w-1/2 lg:mb-0 ">
                        <div class="lg:max-w-md">
                            <div class="px-4 pl-4 mb-6 border-l-4 border-blue-400">
                                <h1 class="mt-2 text-3xl font-semibold uppercase text-blue-400 dark:text-blue-900">
                                    About Us
                                </h1>
                            </div>
                            <p class="px-4 mb-5 text-base leading-7 text-gray-500 dark:text-gray-400">
                                <span className='uppercase text-black font-semibold'>Computer Care Junction </span> that has been in business (All information Technology support and services.) company established since 2017.
                            </p>
                            <p class="px-4 mb-5 text-base leading-7 text-gray-500 dark:text-gray-400">
                                <span className='uppercase'>CCJ </span> is an end to end IT solution provider that help business upgrade to modern IT infrastructure by offering high end solution in information infrastructure. Service solution, Hardware Testing & repairing services. IT rental, IT Sales, information security, Printing solution, networking infrastructure with Report & Certificate CCJ Team believes in provide best possible turnaround time to its customers and this, It operate through a massive network with several branches and own service center spared in different cities.
                            </p>
                        </div>
                    </div>
                    <div class="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
                        <img src={about} alt="..."
                            class="relative z-40 object-cover w-full h-full rounded" />
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap items-center max-w-2xl px-4">
                                <div class="w-full px-4 mb-6 sm:w-1/2 md:w-1/2 lg:mb-6">
                                    <div class="p-6 bg-white rounded shadow-md dark:bg-gray-900">
                                        <span class="text-blue-500 dark:text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="w-10 h-10"
                                                fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                                <path
                                                    d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                <path
                                                    d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                            </svg>
                                        </span>
                                        <p class="mt-4 mb-2 text-3xl font-bold text-gray-700 dark:text-gray-400">2097
                                        </p>
                                        <h2 class="text-sm text-gray-700 dark:text-gray-400">Projects and Plans</h2>
                                    </div>
                                </div>
                                <div class="w-full px-4 mb-6 sm:w-1/2 md:w-1/2 lg:mb-6">
                                    <div class="p-6 bg-white rounded shadow-md dark:bg-gray-900">
                                        <span class="text-blue-500 dark:text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="w-10 h-10"
                                                fill="currentColor" className="bi bi-people-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                                <path fill-rule="evenodd"
                                                    d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" />
                                                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                            </svg>
                                        </span>
                                        <p class="mt-4 mb-2 text-3xl font-bold text-gray-700 dark:text-gray-400">3,590
                                        </p>
                                        <h2 class="text-sm text-gray-700 dark:text-gray-400">Helped people</h2>
                                    </div>
                                </div>
                                <div class="w-full px-4 mb-6 sm:w-1/2 md:w-1/2 lg:mb-6">
                                    <div class="p-6 bg-white rounded shadow-md dark:bg-gray-900">
                                        <span class="text-blue-500 dark:text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="w-10 h-10"
                                                fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                            </svg>
                                        </span>
                                        <p class="mt-4 mb-2 text-3xl font-bold text-gray-700 dark:text-gray-400">6
                                        </p>
                                        <h2 class="text-sm text-gray-700 dark:text-gray-400">Volunteer</h2>
                                    </div>
                                </div>
                                <div class="w-full px-4 mb-6 sm:w-1/2 md:w-1/2 lg:mb-6">
                                    <div class="p-6 bg-white rounded shadow-md dark:bg-gray-900">
                                        <span class="text-blue-500 dark:text-blue-400">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="w-10 h-10"
                                                fill="currentColor" className="bi bi-alarm-fill" viewBox="0 0 16 16">
                                                <path
                                                    d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5zm2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.035 8.035 0 0 0-3.527-3.527z" />
                                            </svg>
                                        </span>
                                        <p class="mt-4 mb-2 text-3xl font-bold text-gray-700 dark:text-gray-400">24x7
                                        </p>
                                        <h2 class="text-sm text-gray-700 dark:text-gray-400">Support Timing</h2>
                                    </div>
                                </div>
                            </div>
        </section>
    );
};

export default About;