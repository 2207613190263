import React from 'react';
import CCTV from '../../Images/BgHickvision.jpg';
import CCTV2 from '../../Images/PTZCamera.png';
import CCTV3 from '../../Images/PTCamera.png';

const SurveillanceSystemPage = () => {
    return (
        <div>
            <div className="flex justify-center items-center transition-transform duration-600 ease-in-out motion-reduce:transition-none">
                <img className="w-110 h-80" src={CCTV} alt="Camera" />
            </div>

            <div className='flex justify-center items-center'>
                <p className=" uppercase rounded-md mb-20 absolute mt-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-600 bg-opacity-60 text-white text-center font-semibold text-xl p-6">
                    Surveillance System Services
                </p>
                <p className='text-center text-base text-gray-500'>We deal in PTZ CCTV cameras, Wireless cameras, biometric systems, Video Door Phone, EPABX System, which are high performance and highly sensitive
                    <br />Premium-Quality Full HD 1080p CCTV Security Cameras Video Surveillance System with Night Vision At Affordable Price
                </p>
            </div>

            <div className="bg-blue-100 p-10 m-20 rounded-md border-1px ">
                <img className="w-110 h-40 mx-auto" src={CCTV2} alt="Camera" />
                <p className="text-center mt-4 text-blue-400 font-semibold">
                    Overview of the key features and benefits of PTZ camera systems:
                </p>
                <ul className="list-disc mt-4 ml-40 mr-40">
                    <li>
                        <p className='mb-4'>A PTZ (Pan-Tilt-Zoom) camera system is a type of surveillance camera that offers remote control over its pan, tilt, and zoom functions. These cameras are commonly used in security and surveillance applications where flexibility and coverage are essential.</p>
                    </li>
                    <li>
                        <p>Pan, Tilt, and Zoom Capabilities: PTZ cameras can pan horizontally (left and right), tilt vertically (up and down), and zoom in or out to capture different areas of interest. The ability to remotely control the camera's movement and zoom allows operators to monitor a wide area with a single camera.</p>
                    </li>
                    <li>
                        <p>High quality camera.</p>
                    </li>
                    <li>
                        <p>
                        Remote Monitoring and Control  Cost-Effective Surveillance Solution
                        </p>
                    </li>
                    <li>
                        <p>
                        Cost-Effective Surveillance Solution
                        </p>
                    </li>
                </ul>
            </div>

            <div className="bg-blue-100 p-10 m-20 rounded-md border-1px ">
                <img className="w-110 h-40 mx-auto" src={CCTV3} alt="Camera" />
                <p className="text-center mt-4 text-blue-400 font-semibold">
                    Overview of the key features and benefits of Dome Network Camera:
                </p>
                <ul className="list-disc mt-4 ml-40 mr-40">
                    <li>
                    <p className='mb-4'><span className='font-semibold'>Wide Coverage:</span> Dome cameras typically offer a wide field of view, allowing them to monitor large areas effectively.
                        They are commonly used in retail stores, office buildings, parking lots, and other public spaces to provide comprehensive surveillance coverage.</p>
                    </li>
                    <li>
                        <p className='mb-4'><span className='font-semibold'>High Definition Imaging:</span> Dome network cameras are available in various resolutions, including HD (High Definition) and even 4K Ultra HD, providing clear and detailed video footage.</p>
                    </li>
                    <li>
                        <p className='mb-4'><span className='font-semibold'>PoE (Power over Ethernet) Support:</span> PoE simplifies installation and reduces cable clutter, making dome cameras easier to deploy and manage, especially in large-scale surveillance systems.</p>
                    </li>
                </ul>
            </div>

        </div>
    );
};

export default SurveillanceSystemPage;