import React from 'react';
import hpServer from '../../Images/ServerRent.jpg';
import dellserver from '../../Images/Network.webp';
import ibmserver from '../../Images/Image2.jpg';

const NetworkSolutionPage = () => {
    return (
        <div>
            <div className='bg-blue-300 rounded-md p-5 mr-20 ml-20'>
                <div className='text-center'>
                    <h2 className='text-2xl mb-5 mt-0 font-semibold'>NETWORK MAINTENANCE SERVICES</h2>
                    <p className='mr-20 ml-20'>Your network’s security and performance rests in the right hands with <br />our leading maintenance plans and packages.</p>
                </div>
            </div>
            <div className="max-w-5xl px-4 py-6 mx-auto lg:py-4 md:px-6">
                <div className="flex">
                    <div className="relative w-1/2 md:mt-20 md:text-small font-base">
                        <p className='mr-5 text-gray-500 font-sm'>
                            Your network is the essential link for the transmission of data which controls information between your server and all devices in your network. At CCJ, we have a dedicated team of experts who keep your business-critical network devices running for maximum availability.
                        </p>
                    </div>
                    <div className="h-60 w-65 bg-blue-400 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                        <img className="-mt-10 ml-8 block w-full/6 md:h-80" src={hpServer} alt="hpServer" />
                        <p className="absolute top-20 left-0 bg-blue-600 bg-opacity-60 text-white text-center font-semibold text-xl w-100 p-6 mr-0 ml-40">
                        Specialized Team<br/> of Experts
                        </p>
                    </div>
                </div>

                <div className="flex">
                    <div className="h-80 w-85 bg-blue-400 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                        <img className="ml-8 block w-full/6" src={dellserver} alt="dellserver" />
                        <p className="absolute top-40 left-0 bg-blue-600 bg-opacity-60 text-white text-center font-semibold text-xl w-100 p-6 mr-0 ml-40">
                        Experts of Networking<br/> Hardware
                        </p>
                    </div>
                    <div className="relative w-1/2 md:mt-16 md:text-small font-base mr-5">
                        <p className='mt-40 ml-14 text-gray-500 font-sm'>
                            CCJ houses certified network engineers who can take care of complex networks with their valuable experience. We even provide IT hardware solutions and services to our clients globally!
                        </p>
                    </div>
                </div>

                <div className="flex">
                    <div className="relative w-1/2 md:mt-10 md:text-small font-base mr-5">
                        <p className='mt-40 text-gray-500 font-sm'>
                            Our team understands your business networks and then gives the best possible network solutions suitable to your requirements. We even troubleshoot complex networks and provide the best possible outcome which saves your precious time and critical network resources.
                        </p>
                    </div>
                    <div className=" mb-20 h-60 w-65 bg-blue-400 mt-40 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                        <img className="-mt-10 ml-8 block w-full/6" src={ibmserver} alt="ibmserver" />
                        <p className="absolute top-10 left-0 bg-blue-600 bg-opacity-60 text-white text-center font-semibold text-xl w-100 p-6 mr-0 ml-40">
                        Troubleshooting Complex Network Problems
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NetworkSolutionPage;