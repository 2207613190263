import React from 'react';
import { Link } from 'react-router-dom';

const JobCard = ({ title, location, description, requirements  }) => {
    return (
        <div className="bg-white p-4 my-4 shadow-md rounded-md">
            <h2 className="text-xl font-semibold">{title}</h2>
            <p className="text-gray-600">{location}</p>
            <p className="mt-2">{description}</p>
            {requirements && (
                <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">Requirements:</h3>
                <ul className="list-disc list-inside">
                    {requirements.map((requirement, index) => (
                    <li key={index} className="text-gray-600">
                        {requirement}
                    </li>
                    ))}
                </ul>
                </div>
            )}
            <div className='mt-4'>
            <Link to="#">
                <button class="border border-blue-900 h-9 hover:bg-blue-900 hover:text-white transition duration-300 ease-in-out
                 px-2 rounded text-dark:blue-900 font-semibold">
                 Apply Now &#8599;
                </button>
            </Link>
            </div>
        </div>
    );
};

export default JobCard;