import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Carousel, initTE } from "tw-elements";
import Home2 from './Home2';
import Home3 from './Home3';
import Home4 from './Home4';
import '../Home/AllHome.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slide1 from '../../Images/Laptop-image.jpg';
import Slide2 from '../../Images/Workstation-Computer.jpeg';
import Slide3 from '../../Images/Image1.jpg';
import Slide4 from '../../Images/Image3.jpg';
import Slide5 from '../../Images/Coding1.jpg';
import PartnerLogo from './PartnerLogo';


const Home = () => {
    useEffect(() => {
        initTE({ Carousel });
      }, []);

      useEffect(() => {
        AOS.init({
          duration: 800, // Animation duration in milliseconds
          easing: 'ease-in-out', // Easing function for the animation
          once: false, // Only animate elements once
        });
      }, []);

    return ( 
        <div>
            <div
            id="carouselExampleCaptions"
            class="relative h-120"
            data-te-carousel-init
            data-te-ride="carousel">
            {/* <!--Carousel indicators--> */}
            <div
                class="absolute bottom-0 left-0 right-0 z-[2] mx-auto mb-4 sm:mx-[15%] sm:mb-4 flex list-none justify-center p-0"
                data-te-carousel-indicators>
                <button
                type="button"
                data-te-target="#carouselExampleCaptions"
                data-te-slide-to="0"
                data-te-carousel-active
                class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-current="true"
                aria-label="Slide 1"></button>
                <button
                type="button"
                data-te-target="#carouselExampleCaptions"
                data-te-slide-to="1"
                class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 2"></button>
                <button
                type="button"
                data-te-target="#carouselExampleCaptions"
                data-te-slide-to="2"
                class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 3"></button>
                <button
                type="button"
                data-te-target="#carouselExampleCaptions"
                data-te-slide-to="3"
                class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 4"></button>
                <button
                type="button"
                data-te-target="#carouselExampleCaptions"
                data-te-slide-to="4"
                class="mx-[3px] box-content h-[3px] w-[30px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-white bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                aria-label="Slide 5"></button>
            </div>

            {/* <!--Carousel items--> */}
            <div
                class="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
                {/* <!--First item-->  width rounded-md blur-background */}
                <div
                class="relative w-full md:h-[75vh] h-fit float-left -mr-[100%] transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-active
                data-te-carousel-item>
                <img
                    src={Slide1}
                    class="width-height"
                    alt="..." />
                <div class="overlay absolute inset-0 bg-black bg-opacity-30"></div>
                <div data-aos="fade-right"
                    class="md:mb-[20vh] absolute inset-x-[15%] bottom-5 py-5 text-left text-white md:block sm:hidden">
                    <h5 class="text-3xl md:text-5xl">Laptops for Sale and Rent</h5>
                    <p class="mt-4 md:text-2xl md:mr-80">
                    Laptops are also available for Office Use, Classrooms, Conferences, Corporate Meetings, Events &amp;<br/>Exhibition with affordable budgets.
                    </p>
                    {/* <Link to="/Laptop">
                    <button class="border border-white hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out
                        px-4 py-2 rounded text-dark:blue-900 font-semibold mt-5">
                        Explore More &rarr;
                    </button>
                    </Link> */}
                </div>
                </div>
                {/* <!--Second item--> */}
                <div
                class="md:h-[75vh] h-fit relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item>
                <img
                    src={Slide2}
                    className="width-height"
                    alt="..." />
                <div class="overlay absolute inset-0 bg-black bg-opacity-30"></div>
                <div
                    class="md:mb-[20vh] absolute inset-x-[15%] bottom-5 py-5 text-left text-white md:block sm:hidden">
                    <h5 class="text-3xl md:text-5xl">Desktops for Sale and Rent</h5>
                    <p class="mt-4 md:text-2xl md:mr-80">
                    Desktops are available for Office Use, Classrooms, Conferences, Corporate Meetings, Events &amp;<br/>Exhibition with affordable budgets.
                    </p>
                    {/* <Link to="/Desktop">
                    <button class="border border-white hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out
                        px-4 py-2 rounded text-dark:blue-900 font-semibold mt-5">
                        Explore More &rarr;
                    </button>
                    </Link> */}
                </div>
                </div>
                {/* <!--Third item--> */}
                <div
                class="md:h-[75vh] h-fit relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item>
                <img
                    src={Slide3}
                    class="width-height"
                    alt="..." />
                <div class="overlay absolute inset-0 bg-black bg-opacity-30"></div>
                <div
                    class="md:mb-[20vh] absolute inset-x-[15%] bottom-5 py-5 text-left text-white md:block sm:hidden">
                    <h5 class="text-3xl md:text-5xl">Server on Rent</h5>
                    <p class="mt-4 md:text-2xl md:mr-60">
                    Entery Level, High Threadings, Multi Cores, Multiple<br/>Processors
                    High-End Branded Servers for Different <br/>Segments, Data Centres, with low budgets
                    </p>
                    {/* <Link to="/Server">
                    <button class="border border-white hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out
                        px-4 py-2 rounded text-dark:blue-900 font-semibold mt-5">
                        Explore More &rarr;
                    </button>
                    </Link> */}
                </div>
                </div>

                {/* <!--Fourth item--> */}
                <div
                class="md:h-[75vh] h-fit relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item>
                <img
                    src={Slide4}
                    class="width-height"
                    alt="..." />
                <div class="overlay absolute inset-0 bg-black bg-opacity-30"></div>
                <div
                    class="md:mb-[20vh] absolute inset-x-[15%] bottom-5 py-5 text-left text-white md:block sm:hidden">
                    <h5 class="text-3xl md:text-5xl">Workstation on Rent</h5>
                    <p class="mt-4 md:text-2xl md:mr-60">
                    Entery Level, Multi Cores, Multiple Processors.<br/>
                    High-End Branded Station for Different<br/> Segments with low budgets
                    </p>
                    {/* <Link to="/WorkStation">
                    <button class="border border-white hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out
                        px-4 py-2 rounded text-dark:blue-900 font-semibold mt-5">
                        Explore More &rarr;
                    </button>
                    </Link> */}
                </div>
                </div>

                {/* <!--Fifth item--> */}
                <div
                class="md:h-[75vh] h-fit relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                data-te-carousel-item>
                <img
                    src={Slide5}
                    class="width-height"
                    alt="..." />
                <div class="overlay absolute inset-0 bg-black bg-opacity-30"></div>
                <div
                    class="md:mb-[20vh] mb-2 absolute inset-x-[15%] bottom-5 py-5 text-left text-white md:block sm:hidden">
                    <h5 class="text-3xl md:text-5xl">Website Development</h5>
                    <p class="mt-4 md:text-2xl md:mr-60">
                    We specialize in creating stunning and responsive<br/>user interfaces using the latest front-end<br/> and back-end technologies.
                    </p>
                    {/* <Link to="/WebPage">
                    <button class="border border-white hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out
                        px-4 py-2 rounded text-dark:blue-900 font-semibold mt-5">
                        Explore More &rarr;
                    </button>
                    </Link> */}
                </div>
                </div>
            </div>

            {/* <!--Carousel controls - prev item--> */}
            <button
                class="absolute bottom-0 left-0 top-0 z-[1] sm:w-[15%] flex w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                type="button"
                data-te-target="#carouselExampleCaptions"
                data-te-slide="prev">
                <span class="inline-block h-8 w-8">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6">
                    <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
                </span>
                <span
                class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Previous</span
                >
            </button>
            {/* <!--Carousel controls - next item--> */}
            <button
                class="absolute bottom-0 right-0 top-0 z-[1] flex sm:w-[15%] w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-white opacity-50 transition-opacity duration-150 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-white hover:no-underline hover:opacity-90 hover:outline-none focus:text-white focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                type="button"
                data-te-target="#carouselExampleCaptions"
                data-te-slide="next">
                <span class="inline-block h-8 w-8">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6">
                    <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
                </span>
                <span
                class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Next</span
                >
            </button>
            </div>
            <PartnerLogo/>
            <Home2/>
            <Home3/>
            <Home4/>
        </div>
    );
};

export default Home;