import React from 'react';
import hpServer from '../../Images/hp-server.jpg';
import dellserver from '../../Images/dell-server.webp';
import ibmserver from '../../Images/ibm-server.webp';

const ServerservicePage = () => {
    return (
        <div>
            <div className='bg-blue-300 rounded-md p-5 mr-20 ml-20'>
                <div className='text-center'>
                    <h2 className='text-2xl mb-5 mt-10 font-semibold'>SERVER MAINTENANCE SERVICE & SUPPORT</h2>
                    <p className='mr-20 ml-20'>CCJunction offers you the most comprehensive annual maintenance contracts for all server types, including the latest generation <br />servers of all types and brands including DELL Servers, HPE Servers, IBM Servers and more! We even provide quality <br />server maintenance and support services for end-of-life and refurbished servers for all major OEMs.</p>
                </div>
            </div>
            <div className="max-w-5xl px-4 py-6 mx-auto lg:py-4 md:px-6">
                <div className="flex">
                    <div className="relative w-1/2 md:mt-10 md:text-small font-base">
                        <p className='mr-5 text-gray-500 font-sm'>
                            <span className='text-blue-400 font-semibold'>CCJunction Proactive Approach – </span> Our proactive approach differs from the normal AMC standard contracts. We take great care not to confront you with server crashes so that your business runs smoothly without any hindrance. However, our qualified professional team is always ready to counter such sudden or unexpected events and server problems. With our flexible on-site and off-site service plans, you can choose the day, time and place at your convenience! We even provide flexible third-party server maintenance services to our beloved clients. For more than two decades, CCJ has been constantly delivering quality server maintenance services to all clients as per their business requirements. Get in touch today with one of our experts to get quality IT Solutions ensuring your business stays up and running just like your valuable servers 24*7*365 days in a year!
                        </p>
                    </div>
                    <div className="h-60 w-65 bg-blue-400 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                        <img className="-mt-10 ml-8 block w-full/6 md:h-80" src={hpServer} alt="hpServer" />
                    </div>
                </div>

                <div className="flex">
                    <div className="h-80 w-85 bg-blue-400 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                        <img className="ml-8 block w-full/6" src={dellserver} alt="dellserver" />
                    </div>
                    <div className="relative w-1/2 md:mt-16 md:text-small font-base mr-5">
                        <p className='mt-0 ml-14 text-gray-500 font-sm'>
                          There are numerous server support companies specializing in server support services that offer comprehensive protection and service plans for HP Server Maintenance, DELL Server Maintenance, and other leading brands. CCJunction offers you first class, comprehensive and reliable server maintenance plans and IT hardware solutions and services.
                        </p>
                        <p className='p-4 ml-10 text-gray-500 font-sm'>
                        With CCJunction fast, efficient and proactive maintenance, you can increase reliability and performance of your servers. You can extend the life of your HP, DELL, IBM, SUN ORACLE and CISCO devices with our experienced server maintenance and support services.
                        </p>
                    </div>
                </div>

                <div className="flex">
                    <div className="relative w-1/2 md:mt-10 md:text-small font-base mr-5">
                        <p className='mt-0 text-gray-500 font-sm'>
                        We know that server maintenance takes time and effort. Not to forget it even requires certain skills for the job. Also, the time required for routine maintenance during business hours is not always feasible. Even for a temporary location, you can rely on our team of specialists to provide on-site server maintenance services. Our certified engineers even support from a remote location at a critical time. CCJ qualified team is constantly focused on providing support services for fast and effective server software to keep your servers running 24*7*365 a year! HP server maintenance or DELL server maintenance, our experienced team can maintain your corresponding rack, tower or blade servers of all types and provide you the best possible server support.
                        </p>
                    </div>
                    <div className=" mb-20 h-60 w-65 bg-blue-400 mt-20 relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                        <img className="-mt-10 ml-8 block w-full/6" src={ibmserver} alt="ibmserver" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServerservicePage;