import React, { useState } from 'react';
import GifImage from "../../Images/GifImg.gif";
import { Link } from 'react-router-dom';

const Home4 = () => {

    const downloadFile = () => {
        const newTabUrl = 'https://join.zoho.com/';
        window.open(newTabUrl, '_blank');
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <h2 className="flex items-center justify-center text-4xl font-semibold text-blue-900">Instant Remote Support</h2>
                <div className="flex md:mr-20 md:ml-20">
                    <div className="relative w-1/2 transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none">
                        <img className="block w-full/6 md:h-80" src={GifImage} alt="..." />
                    </div>
                    <div className="w-1/2 md:mt-20 md:text-lg font-medium text-gray-500">
                        <p>
                            Our certified technicians are expertly trained in repairing software and hardware desktop and laptop computer problems for PC’s and Mac’s etc.
                        </p>
                        <button onClick={toggleDropdown} className="border border-blue-600 hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out
                                px-4 py-2 rounded text-dark:blue-900 font-semibold mt-5">
                            Select Remote &#11183;
                        </button>

                        {/* Start div for DropDown list */}
                        <div className=" ">
                            {isOpen && (
                                <ul
                                    onClick={closeDropdown}
                                    className="absolute py-2 mt-1 font-medium text-blue-600 bg-white rounded shadow-lg dark:text-gray-400 dark:bg-gray-700">
                                    <li>
                                        <Link onClick={downloadFile} to="#" className="block px-4 py-2 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100">
                                            Join Zoho
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="block px-4 py-2 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100">
                                            AnyDesk Remote
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="block px-4 py-2 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100">
                                            TeamViewer Remote
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                        {/* End div for DropDown list */}
                        
                    </div>
                </div>
        </div>
    );
};

export default Home4;