import React from 'react';
import { Link } from 'react-router-dom';

const Error = () => {
    return (
        <section class="relative h-screen bg-center bg-no-repeat bg-cover font-poppins">
        <div class="absolute top-0 left-0 w-full h-full bg-white-900 bg-opacity-50 "></div>
        <div class="flex items-center h-screen">
            <div class="container relative justify-center px-4 mx-auto text-center">
                <h1 class="inline-block mb-8 text-red-700 text-6xl lg:text-9xl">!oop's Error 404</h1>
                <h2 class="mb-8 text-2xl font-semibold text-red-700 lg:text-4xl ">Page not found</h2>
                <p class="mb-8 text-xl text-red-700 ">
                    Sorry! we are unable to find the page that you are looking for...
                </p>
                <div class="flex flex-wrap items-center justify-center">
                <Link to="/">
                            <button class="border border-blue-900 hover:bg-blue-900 hover:text-white transition duration-300 ease-in-out
                            px-4 py-2 rounded text-dark:blue-900 font-semibold mt-5">
                            Go HomePage
                            </button>
                        </Link>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Error;