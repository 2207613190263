import React from 'react';
import logo1 from '../../Images/logo-1.png';
import logo2 from '../../Images/logo-2.png';
import logo4 from '../../Images/LenovoLogo.png';
import logo5 from '../../Images/logo-7.png';
import logo6 from '../../Images/Bosch-logo.jpg';
import logo7 from '../../Images/D-Link_Logo.jpg';
import logo8 from '../../Images/Palo-Alto-Logo.png';

const PartnerLogo = () => {
    return (
            <div className='flex w-full h-auto md:mt-10 md:pr-20 md:pl-20 mb-0'>
                <div className='md:mr-10 md-ml-10'>
                    <img src={logo2} alt='Dell' className='h-18 w-20'/>
                </div>
                <div className='md:mr-10 md-ml-10 mt-4'>
                    <img src={logo4} alt='Lenovo' className='h-26 w-28'/>
                </div>
                <div className='md:mr-10 md-ml-10'>
                    <img src={logo1} alt='HP' className='h-18 w-20'/>
                </div>
                <div className='md:mr-10 md-ml-10'>
                    <img src={logo5} alt='....' className='h-20'/>
                </div>
                <div className='md:mr-10 md-ml-10'>
                    <img src={logo6} alt='....' className='h-12 md:mt-4'/>
                </div>
                <div className='md:mr-10 md-ml-10'>
                    <img src={logo7} alt='....' className='h-16 md:mt-2'/>
                </div>
                <div className='md:mr-10 md-ml-10'>
                    <img src={logo8} alt='....' className='h-12 md:mt-4'/>
                </div>
            </div>
    );
};

export default PartnerLogo;