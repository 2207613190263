import React from 'react';
import { Link } from 'react-router-dom';
import Technical from '../../Images/Technical.jpg';

const Blog = () => {

    const formatedDate = new Date('2023-10-10').toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    return (
        <section class="flex items-center py10 bg-white-100 lg:py-24 font-poppins dark:bg-gray-800 ">
        <div class="justify-center flex-1 max-w-4xl px-4 py-4 mx-auto text-left lg:py-10 ">
            <div class="mb-20 text-center">
                <span
                    class="block mb-4 text-3xl font-semibold leading-4 tracking-widest text-center text-blue-500 uppercase dark:text-gray-400">
                    Our blogs
                </span>
            </div>
            <div class="grid grid-cols-1 dark:bg-gray-900 bg-white shadow-lg rounded-md mb-6 lg:grid-cols-[1fr,70%]   gap-4">
                <div className=''>
                    <img src={Technical} alt="...."
                        className="object-cover w-full rounded-md h-80 lg:h-60"/>
                </div>
                <div class="px-4 py-4 lg:px-0">
                    <Link to="#"
                        class="px-2.5  py-0.5 mr-2 text-xs text-gray-700 bg-gray-200 rounded hover:bg-blue-600 dark:bg-gray-700 dark:text-gray-400 hover:text-gray-100 dark:hover:bg-gray-800">
                        Technical</Link>
                    <Link to="#">
                        <h2
                            class="mt-3 mb-3 text-xl font-semibold text-gray-600 hover:text-blue-600 dark:text-gray-400">
                            Overview of the key technical components of computers</h2>
                    </Link>
                    <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">
                    Central Processing Unit (CPU)
                    Role of the CPU in a computer.
                    Understanding clock speed, cores, and cache.
                    Random Access Memory (RAM)
                    Purpose of RAM in computer performance.
                    Different types of RAM and their characteristics.
                    </p>
                    <span class="text-xs font-medium text-gray-700 dark:text-gray-400">{formatedDate}</span>
                </div>
            </div>
            <div class="grid grid-cols-1 dark:bg-gray-900 bg-white shadow-lg rounded-md mb-6 lg:grid-cols-[1fr,70%]   gap-4">
                <div>
                    <img src="https://i.postimg.cc/GmM9VxxJ/pexels-pavel-danilyuk-8381916.jpg" alt=""
                        class="object-cover w-full rounded-md h-80 lg:h-60"/>
                </div>
                <div class="px-4 py-4 lg:px-0">
                    <Link to="#"
                        class="px-2.5  py-0.5 mr-2 text-xs text-gray-700 bg-gray-200 dark:hover:bg-gray-800 rounded hover:bg-blue-600 dark:bg-gray-700 dark:text-gray-400 hover:text-gray-100">
                        Development</Link>
                    <Link to="#">
                        <h2
                            class="mt-3 mb-3 text-xl font-semibold text-gray-600 hover:text-blue-600 dark:text-gray-400">
                            Overview of the technologies involved in front-end development</h2>
                    </Link>
                    <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">
                    HTML Basics
                    Understanding the structure of HTML.
                    Common HTML tags and attributes.
                    CSS Styling
                    Introduction to CSS for styling HTML elements.
                    Box model, selectors, and styling techniques.
                    JavaScript Essentials
                    Basics of JavaScript programming.
                    DOM manipulation for dynamic content.
                    </p>
                    <span class="text-xs font-medium text-gray-700 dark:text-gray-400">{formatedDate}</span>
                </div>
            </div>
            <div class="grid grid-cols-1 bg-white shadow-lg rounded-md dark:bg-gray-900 mb-6 lg:grid-cols-[1fr,70%]   gap-4">
                <div>
                    <img src="https://i.postimg.cc/GhwqZcSm/pexels-designecologist-1627935.jpg" alt=""
                        class="object-cover w-full rounded-md h-80 lg:h-60"/>
                </div>
                <div class="px-4 py-4 lg:px-0">
                    <Link to="#"
                        class="px-2.5  py-0.5 mr-2 text-xs dark:hover:bg-gray-800 text-gray-700 bg-gray-200 rounded hover:bg-blue-600 dark:bg-gray-700 dark:text-gray-400 hover:text-gray-100">
                        Laptop issues</Link>
                    <Link href="#">
                        <h2
                            class="mt-3 mb-3 text-xl font-semibold text-gray-600 hover:text-blue-600 dark:text-gray-400">
                            Common Laptop Issues and Diagnostics</h2>
                    </Link>
                    <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">
                    Power Issues
                    Laptop not turning on or charging.
                    Checking power cables, adapters, and battery.
                    Startup Problems
                    Diagnosing boot failures and system crashes.
                    Troubleshooting BIOS/UEFI issues.
                    Display Problems
                    Blank screen or distorted visuals.
                    Diagnosing and fixing graphics-related issues.
                    </p>
                    <span class="text-xs font-medium text-gray-700 dark:text-gray-400">{formatedDate}</span>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Blog;