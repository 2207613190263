import React from 'react';

const WebPage = () => {
    return (
        <div className='text-center text-3xl mb-20 mt-20'>
            Coming Soon.....
        </div>
    );
};

export default WebPage;