import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../Images/CCJ_Logo_High.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Navbar/Navbar.css';

const Navbar = () => {
  const location = useLocation();

  // Check if the current location matches the home route
  const isHomeActive = location.pathname === '/';
  const isAboutActive = location.pathname === '/About';
  const isRentalActive = location.pathname === '/ITRental';
  const isServiceActive = location.pathname === '/ITHardwarePage';
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  const [open, setOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const closeNavMenu = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`sticky top-0 z-50 flex items-center justify-between bg-white ${scrolling ? 'shadow-md bg-gradient-to-l from-blue-200 via-blue-200 bg-clip opacity-85' : ''} `}>
      <Link to='/'><img className='w-80  sm:ml-10' src={logo} alt="logo" /></Link>
      <div className="flex justify-between lg:space-x-9">
        <div className="lg:hidden">
          <button
            className="flex items-center mt-1 mr-14 px-3 py-2 text-blue-600 border border-blue-200 rounded dark:text-gray-400 navbar-burger hover:text-blue-800 hover:border-blue-300 lg:hidden"
            onClick={() => setOpen(!open)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
            </svg>
          </button>
        </div>
        <ul className="hidden lg:w-auto lg:space-x-9 lg:items-center lg:flex">
          <li><Link to="/" className={isHomeActive ? 'Home text-dark:blue-900 hover:text-white-500' : ''}><span className='hover:text-white-500'>Home</span></Link></li>
          <li><Link to="/About" className={isAboutActive ? 'Home text-dark:blue-900 hover:text-white-500' : ''}><span className='hover:text-white-500'>About us</span></Link></li>
          <li><Link to="/ITRental" className={isRentalActive ? 'Home text-dark:blue-900 hover:text-white-500' : ''}><span className='hover:text-white-500'>IT Rental</span></Link></li>
          <li className='group flex text-dark:blue-500 hover:text-white-500 hover:cursor-pointer'>
            <button className='flex'>
              <p className={isServiceActive ? 'Home' : ''}>Services</p>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" strokeWidth="2" stroke="black" class="w-4 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <div className='hidden absolute pt-10 z-10 bg-grey-200 group-hover:block'>
              <div className="px-2 pt-2 pb-4 bg-white bg-gray-200 shadow-lg rounded-md">
                <div className="text-black grid grid-cols-1 gap-4">
                <Link onClick={closeNavMenu} className='block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/ITHardwarePage'>IT Hardware Solution & Services</Link>
                  <Link onClick={closeNavMenu} className='block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/ServerServicePage'>Server Maintenance & Service</Link>
                  <Link onClick={closeNavMenu} className='block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/RefurbishedPage'>Refurbished Laptop & Desktop</Link>
                  <Link onClick={closeNavMenu} className='block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/WebPage'>Web Development</Link>
                  <Link onClick={closeNavMenu} className='block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/RemoteSupport'>Remote Support</Link>
                  <Link onClick={closeNavMenu} className='block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/SurveillanceSystemPage'>Surveillance Security System</Link>
                  <Link onClick={closeNavMenu} className='block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/NetworkSolution'>Network Maintenance Service & Support</Link>
                </div>
              </div>
            </div>
          </li>
          <li className='group flex text-dark:blue-500 hover:text-blue-500 hover:cursor-pointer'>
            <button className='flex'>
              <p>Resources</p>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" strokeWidth="2" stroke="black" class="w-4 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <div className='absolute hidden pt-10 z-10 bg-grey-100 group-hover:block'>
              <div className="px-2 pt-2 pb-4 bg-white bg-gray-200 shadow-lg rounded-md">
                <div className="text-black grid grid-cols-1 gap-4 md:grid-cols-1">
                  <Link className='hover:text-blue-600
                         block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:translate-x-1 duration-300
                         hover:transition ease-in-out delay-150' to='/Blogs'>Blog</Link>
                  <Link className='block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/News'>News</Link>
                  <Link className='block px-2 py-1 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100 hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/Careers'>Carrers</Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div className="hidden lg:block pr-8">
          <Link to="/Contact">
            <button class="border border-blue-500 h-11 hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out
                 px-4 rounded text-dark:blue-500 font-semibold">
              Contact Us
            </button>
          </Link>
        </div>
      </div>

      {/* Mobile Sidebar */}
      <div className={`fixed inset-0 w-full bg-gray-900 opacity-25 dark:bg-gray-400 lg:hidden ${open ? 'translate-x-0 ease-in-opacity-100' : '-translate-x-full ease-out opacity-0'}`}></div>
      <div className={`absolute inset-0 z-10 h-screen p-3 text-blue-900 duration-500 transform bg-blue-50 dark:bg-gray-800 w-80 lg:hidden lg:transform-none lg:relative ${open ? 'translate-x-0 ease-in-opacity-100' : '-translate-x-full ease-out opacity-0'}`}>
        <div data-aos="fade-right" className="flex justify-between lg:">
          <Link onClick={closeNavMenu} to='/'>
            <img src={logo} alt="logo" />
          </Link>
          <button className="p-2 text-gray-700 rounded-md dark:text-gray-400 hover:text-blue-300 lg:hidden" onClick={() => setOpen(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </button>
        </div>
        <ul className="px-4 text-left mt-7">
          <li className="pb-3"><Link to="/" onClick={closeNavMenu} className="text-dark:blue-900 hover:text-blue-700">Home</Link></li>
          <li className="pb-3"><Link to="/About" onClick={closeNavMenu} className="text-dark:blue-900 hover:text-blue-700">About Us</Link></li>
          <li><Link to="/ITRental" className={isHomeActive ? 'Home Active text-dark:blue-900 hover:text-blue-500' : ''}>IT Rental</Link></li>
          <li className='group flex text-dark:blue-900 hover:text-blue-700 hover:cursor-pointer'>
            <button className='flex'>
              <p className='pb-3'>Services</p>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" strokeWidth="2" stroke="#D5DAE2" class="w-4 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <div className='hidden absolute pt-10 z-10 bg-grey-200 group-hover:block'>
              <div className="px-2 pt-2 pb-4 bg-white bg-gray-200 shadow-lg rounded-md">
                <div className="text-black grid grid-cols-1 gap-4 md:grid-cols-2">
                  <Link onClick={closeNavMenu} className='duration-300 hover:translate-x-1 hover:transition ease-in-out delay-150' to='/ITHardwarePage'>IT Hardware Solution & Services</Link>
                  <Link onClick={closeNavMenu} className='duration-300 hover:translate-x-1 hover:transition ease-in-out delay-150' to='/ServerServicePage'>Server Maintenance Service</Link>
                  <Link onClick={closeNavMenu} className='duration-300 hover:translate-x-1 hover:transition ease-in-out delay-150' to='/Refurbished'>Refurbished Laptops & Desktop</Link>
                  <Link onClick={closeNavMenu} className='duration-300 hover:translate-x-1 hover:transition ease-in-out delay-150' to='/WebPage'>Web Development</Link>
                  <Link onClick={closeNavMenu} className='duration-300 hover:translate-x-1 hover:transition ease-in-out delay-150' to='/NetworkSolution'>Network Maintenance Service & Support</Link>
                  <Link onClick={closeNavMenu} className='duration-300 hover:translate-x-1 hover:transition ease-in-out delay-150' to='/RemoteSupport'>Remote Support</Link>
                  <Link onClick={closeNavMenu} className='duration-300 hover:translate-x-1 hover:transition ease-in-out delay-150' to='/SurveillanceSystemPage'>Surveillance Security System</Link>
                </div>
              </div>
            </div>
          </li>
          <li className='group flex text-dark:blue-900 hover:text-blue-700 hover:cursor-pointer'>
            <button className='flex'>
              <p className='pb-3'>Resources</p>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" strokeWidth="2" stroke="#D5DAE2" class="w-4 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </button>
            <div className='absolute hidden pt-10 z-10 bg-grey-100 group-hover:block'>
              <div className="px-2 pt-2 pb-4 bg-white bg-gray-200 shadow-lg rounded-md">
                <div className="text-black grid grid-cols-1 gap-4 md:grid-cols-1">
                  <Link onClick={closeNavMenu} className='hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/Blogs'>Blog</Link>
                  <Link onClick={closeNavMenu} className='hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/News'>News</Link>
                  <Link onClick={closeNavMenu} className='hover:text-blue-600 hover:translate-x-1 duration-300 hover:transition ease-in-out delay-150' to='/Careers'>Carrers</Link>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div className="block mt-5 lg:hidden">
          <Link onClick={closeNavMenu} to="/Contact">
            <button class=" inline-block w-full px-4 py-3 mr-2 leading-none text-center border border-blue-900 h-11 hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out
                 px-4 rounded text-blue-900">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
