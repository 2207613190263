import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Images/logo512.png';

const Footer = () => {
    return (

<footer
  class="bg-gradient-to-r from-blue-400 to-black bg-clip text-center text-white dark:bg-neutral-600 dark:text-neutral-200 lg:text-left">
  <div
    class="flex items-center justify-center border-b-2 border-white p-6 dark:border-white lg:justify-between">
    <div class="mr-12 hidden lg:block">
      <span>Get connected with us on social networks:</span>
    </div>
    {/* <!-- Social network icons container --> */}
    <div class="flex justify-center">
      <Link to="/" class="mr-6 text-white dark:text-blue-900">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-5"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
        </svg>
      </Link>
      <Link to="/" class="mr-6 text-white dark:text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-5"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
        </svg>
      </Link>
      <Link to="/" class="mr-6 text-red-600 dark:text-red-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M7 11v2.4h3.97c-.16 1.029-1.2 3.02-3.97 3.02-2.39 0-4.34-1.979-4.34-4.42 0-2.44 1.95-4.42 4.34-4.42 1.36 0 2.27.58 2.79 1.08l1.9-1.83c-1.22-1.14-2.8-1.83-4.69-1.83-3.87 0-7 3.13-7 7s3.13 7 7 7c4.04 0 6.721-2.84 6.721-6.84 0-.46-.051-.81-.111-1.16h-6.61zm0 0 17 2h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z"
            fill-rule="evenodd"
            clip-rule="evenodd" />
        </svg>
      </Link>
      <Link to="/" class="mr-6 text-blue-600 dark:text-blue-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-4.5"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
        </svg>
      </Link>
      <Link to="/" class="text-black-600 dark:text-black-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-5"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
        </svg>
      </Link>
    </div>
  </div>

  
  <div class="mx-6 py-10 text-center md:text-left">
    <div class="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
      {/* <!-- TW elements section --> */}
      <div class="">
        <h6
          class="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
          <img className='h-12 w-12 mr-3' src={logo} alt='....'/>
          Computer Care Junction 
        </h6>
        <p>
        We are one of the computer service and repair for genuinely excellent computer repair services in New Delhi, 
        providing Home or Business Support and all Network Problems, Servers through remotely & Personal Visit.
        </p>
      </div>
      {/* <!-- Products section --> */}
      <div class="">
        <h6
          class="mb-4 flex justify-center font-semibold uppercase md:justify-start">
          Services
        </h6>
        <p class="mb-4 hover:underline">
          <Link to="/" class="text-blacl-600 dark:text-black-600"
            >Workstation</Link>
        </p>
        <p class="mb-4 hover:underline">
          <Link to="/" class="text-blacl-600 dark:text-black-600"
            >Laptop & PC Repairs</Link>
        </p>
        <p class="mb-4 hover:underline">
          <Link to="/" class="text-blacl-600 dark:text-black-600"
            >Refurbished Laptop</Link>
        </p>
        <p class="mb-4 hover:underline">
          <Link to="/" class="text-blacl-600 dark:text-black-600"
            >Rental Laptop</Link>
        </p>
        <p class="mb-4 hover:underline">
          <Link to="/" class="text-blacl-600 dark:text-black-600"
            >Rental Servers</Link>
        </p>
        <p class="mb-4 hover:underline">
          <Link to="/" class="text-blacl-600 dark:text-black-600"
            >Web Development</Link>
        </p>
      </div>
      {/* <!-- Useful links section --> */}
      <div class="">
        <h6
          class="mb-4 flex justify-center font-semibold uppercase md:justify-start">
          Useful links
        </h6>
        <p class="mb-4 hover:underline">
          <Link to="/Blogs" class="text-blacl-600 dark:text-black-600"
            >Blog</Link>
        </p>
        <p class="mb-4 hover:underline">
          <Link to="/news" class="text-blacl-600 dark:text-black-600"
            >News</Link>
        </p>
        <p class="mb-4 hover:underline">
          <Link to="/Careers" class="text-blacl-600 dark:text-black-600"
            >Carrers</Link>
        </p>
        <p class="mb-4 hover:underline">
          <Link to="/" class="text-blacl-600 dark:text-black-600 hover:text-underline"
            >Help Support</Link>
        </p>
      </div>
      {/* <!-- Contact section --> */}
      <div>
        <h6
          class="mb-4 flex justify-center font-semibold uppercase md:justify-start">
          Contact Us
        </h6>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="mr-3 h-6 w-7">
            <path
              d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
            <path
              d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
          </svg>
          Regd. Office: RZ 3178/36 Tugalkabad Ext.New Delhi-110019
        </p>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="mr-3 h-5 w-5">
            <path
              d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path
              d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
          </svg>
          info@computercarejunction.in
        </p>
        <p class="mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="mr-3 h-5 w-5">
            <path
              fill-rule="evenodd"
              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
              clip-rule="evenodd" />
          </svg>
          +91 9717359085, 7042017876
        </p>
        <p class="flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="mr-3 h-5 w-5">
            <path
              fill-rule="evenodd"
              d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z"
              clip-rule="evenodd" />
          </svg>
          +91 01144725411
        </p>
      </div>
    </div>
  </div>

  {/* <!--Copyright section--> */}
  <div class="bg-neutral-200 p-6 text-center dark:bg-neutral-700">
    <span className='text-black'>All Copyright reserved © 2023:</span>
    <Link
      class="font-semibold bg-gradient-to-r from-blue-600 to-black bg-clip-text text-transparent dark:text-neutral-400 hover:underline"
      to="http://www.computercarejunction.in/"
      > Designed & Developed by Computer Care Junction</Link>
  </div>
</footer>
    );
};

export default Footer;