import React from 'react';
import Laptop1 from '../../Images/Think-full.png';
import Desktop from '../../Images/DellDesktop.jpg';

const RefurbishedPage = () => {
    return (
        <>
            <div className="ml-60 mb-10 mt-20 md:w-[50rem] md:h-[20.5rem] w-[20.5rem] h-[40rem] p-4 rounded-2xl bg-gray-100 shadow-lg dark:shadow-slate-700 flex flex-col ease-linear duration-300 md:flex-row-reverse">
                <div className=" h-full w-full  shadow-md rounded-2xl basis-2/3 relative">
                    <div className=" text-white z-10 bg-blue-400 absolute pl-8 pr-8 pb-2 pt-2  rounded-tl-2xl rounded-br-2xl font-semibold">
                        <h1>Warranty</h1>
                    </div>
                    <div className="h-full w-full relative border-2 border-white rounded-2xl">
                        <img
                            src={Laptop1}
                            className="mt-12 rounded-2xl"
                            alt='laptop'
                        />
                    </div>
                </div>

                <div className=" h-full w-full mr-2 rounded-2xl">
                    <p className="m-2 font-bold pl-1 text-lg text-blue-400">Refurbished Laptops</p>
                    <h1 className="m-2 text-2xl font-bold dark:text-white">
                        CCJ promise to best Laptops offer a cost-effective solution for individuals.
                    </h1>

                    <h2 className='text-blue-400 m-2 pt-10 font-bold text-2xl'>Dell
                        <span className='text-red-700'> Lenovo ThinkPad </span> <span className='text-black'>HP </span></h2>

                    <div className=" pt-10 pr-2 flex flex-row justify-start flex-wrap">
                        <div className="flex flex-row m-2">
                            <h1 className="dark:text-white font-semibold">1. Warranty</h1>
                        </div>
                        <div className="flex flex-row m-2">
                            <h1 className="dark:text-white font-semibold">2. Battery Backup</h1>
                        </div>
                        <div className="flex flex-row m-2">
                            <h1 className="dark:text-white font-semibold">3. Affordable price</h1>
                        </div>
                        <div className="flex flex-row m-2">
                            <h1 className="dark:text-white font-semibold">4. 7 days refund</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ml-60 mb-10 mt-20 md:w-[50rem] md:h-[20.5rem] w-[20.5rem] h-[40rem] p-4 rounded-2xl bg-gray-100 shadow-lg dark:shadow-slate-700 flex flex-col ease-linear duration-300 md:flex-row-reverse">
                <div className=" h-full w-full  shadow-md rounded-2xl basis-2/3 relative">
                    <div className=" text-white z-10 bg-blue-400 absolute pl-8 pr-8 pb-2 pt-2  rounded-tl-2xl rounded-br-2xl font-semibold">
                        <h1>Warranty</h1>
                    </div>
                    <div className="h-full w-full relative border-2 border-white rounded-2xl">
                        <img
                            src={Desktop}
                            className="mt-12 rounded-2xl"
                            alt='laptop'
                        />
                    </div>
                </div>

                <div className=" h-full w-full mr-2 rounded-2xl">
                    <p className="m-2 font-bold pl-1 text-lg text-blue-400">Refurbished Desktop </p>
                    <h1 className="m-2 text-2xl font-bold dark:text-white">
                        CCJ promise to best Desktops offer a cost-effective solution for individuals.
                    </h1>

                    <h2 className='text-blue-400 m-2 pt-10 font-bold text-2xl'>Dell
                        <span className='text-red-700'> Lenovo </span> <span className='text-black'>HP </span> Samsung</h2>

                    <div className=" pt-10 pr-2 flex flex-row justify-start flex-wrap">
                        <div className="flex flex-row m-2">
                            <h1 className="dark:text-white font-semibold">1. Warranty</h1>
                        </div>
                        <div className="flex flex-row m-2">
                            <h1 className="dark:text-white font-semibold">2. Battery Backup</h1>
                        </div>
                        <div className="flex flex-row m-2">
                            <h1 className="dark:text-white font-semibold">3. Affordable price</h1>
                        </div>
                        <div className="flex flex-row m-2">
                            <h1 className="dark:text-white font-semibold">4. 7 days refund</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RefurbishedPage;