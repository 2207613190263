import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
    //const [isOpen, setIsOpen] = useState(false);
    return (
        <div className='bg-gradient-to-r from-blue-600 to-black bg-clip h-10'>
            <div className='flex items-center justify-evenly gap-2'>
                <div className='mt-2 flex items-center gap-2 text-white hover:underline hover:text-white transform transition-transform hover:scale-90'>
                    <Link to='/Contact'>
                    <button className='flex items-center gap-2'>
                    <i className="fa fa-envelope"></i>
                    <p className='font-semibold'>Book Free Consultation</p>
                    </button>
                    </Link>
                    {/* {isOpen && (
                    <div className="fixed flex items-center justify-center h-screen bg-gray-500 bg-opacity-50">
                    <div className="fixed mx-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-gray-900 sm:my-8 sm:max-w-lg sm:w-full">
                        <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 rounded-full dark:bg-blue-400 sm:mx-0 sm:h-10 sm:w-10">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="w-5 h-5 text-blue-600 dark:text-gray-100 bi bi-exclamation-triangle"
                                viewBox="0 0 16 16"
                            >
                                <path
                                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06a.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                                />
                                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                            </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h2 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">
                                Are you sure want to Deactivate account?
                            </h2>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                Are you sure you want to deactivate your account? All of your data will be permanently removed. This action cannot be undone.
                                </p>
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-blue-500 border border-transparent border-blue-600 rounded-md shadow-sm bg-blue-50 hover:text-gray-100 hover:bg-blue-700 focus:outline-none dark:hover:bg-blue-500 dark:text-gray-100 dark:bg-blue-400 focus:ring-2 focus:ring-offset-2 dark:focus:ring-0 dark:focus:ring-offset-0 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                            Deactivate
                        </button>
                        <button
                            onClick={() => setIsOpen(false)}
                            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-700 dark:text-gray-100 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm dark:focus:ring-0 dark:focus:ring-offset-0"
                        >
                            Cancel
                        </button>
                        </div>
                    </div>
                    </div>
                    )} */}
                </div>
                <div className='bg-gradient-to-r from-white via-white to-white text-transparent bg-clip-text flex items-center gap-2 transform transition-transform hover:scale-110'>
                <i className="fa fa-envelope mt-2"></i>
                <p className='font-semibold mt-1.5'>info@computercarejunction.in</p>
                </div>

                <div className='bg-gradient-to-r from-white via-white to-white text-transparent bg-clip-text flex items-center gap-2 transform transition-transform hover:scale-110'>
                <i className="fa fa-phone mt-2"></i>
                <p className='font-semibold mt-1.5'>+91 9717359085, 7042017876</p>
                </div>
            </div>
        </div>
    );
};

export default Hero;