import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './Components/Animation/ScrollToTop';
import Navbar from "./Components/Navbar/NavBar";
import Home from "./Components/Home/Home";
import About from './Components/Home/About';
import Contact from './Components/Home/Contact';
import Home2 from './Components/Home/Home2';
import Home3 from './Components/Home/Home3';
import Home4 from './Components/Home/Home4';
import Footer from './Components/Home/Footer';
import Error from './Components/Home/Error';
import WebPage from './Components/Pages/WebPage';
import Blog from './Components/Pages/Blog';
import CareerPage from './Components/Pages/CareerPage';
import Hero from './Components/Home/Hero';
import NewsPage from './Components/Pages/NewsPage';
import RemoteSupport from './Components/Services/RemoteSupport';
import PartnerLogo from './Components/Home/PartnerLogo';
import ITRentalPage from './Components/Pages/ITRentalpage';
import NetworkSolutionPage from './Components/Services/NetworkSolutionPage';
import ITHardwarePage from './Components/Services/ITHardwarePage';
import ServerservicePage from './Components/Services/ServerServicePage';
import SurveillanceSystemPage from './Components/Services/SurveillanceSystemPage';
import RefurbishedPage from './Components/Services/RefurbishedPage';

const App = () => {
  return (
    <Router>
      <Hero/>
      <Navbar/>
      <ScrollToTop />
      <Routes>
        {/* Home Page */}
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/PartnerLogo" element={<PartnerLogo/>} />
        <Route exact path="/Home2" element={<Home2/>} />
        <Route exact path="/Home3" element={<Home3/>} />
        <Route exact path="/Home4" element={<Home4/>} />
        <Route exact path="/About" element={<About/>} />
        <Route exact path="/Contact" element={<Contact/>} />
        {/* Error Page */}
        <Route path='*' element={<Error/>} />
        {/* Rentals menu Page */}
        <Route exact path="/ITRental" element={<ITRentalPage/>} />
        {/* Resources Page */}
        <Route exact path="/Blogs" element={<Blog/>} />
        <Route exact path="/Careers" element={<CareerPage/>} />
        <Route exact path="/News" element={<NewsPage/>} />
        {/* Services menu Page */}
        <Route exact path="/WebPage" element={<WebPage/>} /> 
        <Route exact path="/NetworkSolution" element={<NetworkSolutionPage/>} /> 
        <Route exact path="/RemoteSupport" element={<RemoteSupport/>} />
        <Route exact path="/ITHardwarePage" element={<ITHardwarePage/>} />
        <Route exact path="/ServerservicePage" element={<ServerservicePage/>} />
        <Route exact path="/SurveillanceSystemPage" element={<SurveillanceSystemPage/>} />
        <Route exact path="/RefurbishedPage" element={<RefurbishedPage/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
