import React from 'react';
import Contract from '../../Images/Agreement.png';
import Expert from '../../Images/Experties.png';
import Coverage from '../../Images/flexible.png';
import Support from '../../Images/support.png';
import maintenance from '../../Images/ITMaintenance.jpeg';

const ITHardwarePage = () => {
    return (
        <section className='h-100%'>
            <div className='h-auto'>
                <div class="flex bg-blue-300 ml-40 mr-40 h-20">
                    <h2
                        class="mb-0 ml-40 mt-4 text-base text-center font-semibold leading-tight tracking-tighter text-gray-700 dark:text-gray-300 md:text-3xl">
                        IT HARDWARE SOLUTION
                    </h2>
                    <p class="mb-4 text-base w-80 ml-40 text-gray-900 dark:text-gray-900">
                        We’re globally, covering all server, storage networking devices for our clients Data Centre and Enterprises.
                    </p>
                </div>

                <div class="block text-center ml-40 mr-40 p-20 bg-gray-100">
                    <h2
                        class="mb-4 mt-4 text-base font-semibold leading-tight tracking-tighter text-gray-700 dark:text-gray-300 md:text-3xl">
                        Here’s why we should be your IT Solution Provider
                    </h2>
                    <p class="w-100 mb-4 text-lg font-medium text-gray-900 dark:text-gray-900">
                        Our team of experts is here and will be happy to help you. Send us a message or give us a call depending on your requirements.
                    </p>
                    <p class="mt-4 text-base text-gray-700 dark:text-gray-700">
                        “Our IT Support & Maintenance Services provide a comprehensive multi-vendor support package that will protect your investment.”
                    </p>
                </div>

                <div className='flex justify-center'>
                    <div className='flex items-center p-5'>
                        <img className='h-14 w-14' src={Contract} alt='Contract' />
                        <p className='text-black ml-4'>Custom Service Level Agreements</p>
                    </div>
                    <div className='flex items-center p-5'>
                        <img className='h-14 w-14' src={Expert} alt='Contract' />
                        <p className='text-black ml-4'>Certified Engineers</p>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <div className='flex items-center p-5 w-80'>
                        <img className='h-14 w-14' src={Support} alt='Contract' />
                        <p className='text-black ml-4 '>24X7 Support</p>
                    </div>
                    <div className='flex items-center p-5'>
                        <img className='h-14 w-14' src={Coverage} alt='Contract' />
                        <p className='text-black ml-4'>Flexible Coverage</p>
                    </div>
                </div>

                <div className=' flex justify-center'>
                    <h2 className='text-blue-400 font-semibold text-2xl mt-20 p-2'>IT MAINTENANCE SERVICES</h2>
                </div>
                <div className='flex p-5'>
                    <div className='w-1/2 p-5'>
                      <img className='rounded-md' src={maintenance} alt='Maintenance'/>
                    </div>
                    <div className='w-1/2 p-5'>
                       <p className='mb-10'>The importance of a robust IT infrastructure cannot be overstated in the present times. Almost all essential facets of a business require the IT infrastructure to function at an optimal level round the clock with storage maintenance. But merely buying the best quality equipment is not going to suffice. You need to invest in <span className='text-blue-400'>IT hardware & network maintenance</span> as well, to make sure that your work is not hampered in any way. But in order to ensure that you are able to enjoy a hassle-free user experience, you must look for an experienced and responsible service partner with a proven track record.</p>
                       <p className='mb-10'>Your request for a reliable service partner ends with us. At<span className='text-blue-400'> CCJunction</span>, we are the leading vendors for <span className='text-blue-400'> refurbished IT Hardware</span>refurbished IT Hardware, and we specialize in servers. We have been catering to the needs of our clients from across the globe. Our experienced technicians are not only experts at <span className='text-blue-400'>installing refurbished servers</span> but also offer excellent maintenance services for your essential IT infrastructure and paraphernalia. Whether you require a server motherboard or as server hard drive, we can provide best IT services and solutions and in a seamless manner.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ITHardwarePage;