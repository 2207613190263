import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import remote from '../../Images/support.png';

const RemoteSupport = () => {

    const downloadFile = () => {
        const newTabUrl = 'https://join.zoho.com/';
        window.open(newTabUrl, '_blank');
    };

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };

    return (
        <section class="flex items-center bg-gray-100 py-11 font-poppins dark:bg-gray-800">
            <div class="max-w-6xl px-4 mx-auto">
                <div class="max-w-4xl mx-auto text-center">
                    <h2
                        class="mb-6 text-3xl font-semibold leading-tight tracking-tighter text-gray-700 dark:text-gray-300 md:text-5xl">
                        Instant remote support
                    </h2>
                    <p class="mb-4 text-lg font-medium text-gray-500 dark:text-gray-400">
                        Are technical glitches slowing you down? Say goodbye to frustrating computer issues with our instant remote support services!
                    </p>
                    <button onClick={toggleDropdown} className="border border-blue-600 hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out
                                px-4 py-2 rounded text-dark:blue-900 font-semibold mt-0">
                        Select Remote &#11183;
                    </button>

                    {/* Start div for DropDown list */}
                    <div className=" ">
                        {isOpen && (
                            <ul
                                onClick={closeDropdown}
                                className="relative py-2 mt-1 font-medium text-blue-600 bg-white rounded shadow-lg dark:text-gray-400 dark:bg-gray-700">
                                <li>
                                    <Link onClick={downloadFile} to="#" className="block px-4 py-2 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100">
                                        Join Zoho
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="block px-4 py-2 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100">
                                        AnyDesk Remote
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="block px-4 py-2 whitespace-no-wrap dark:hover:bg-gray-600 hover:bg-blue-100">
                                        TeamViewer Remote
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </div>
                    {/* End div for DropDown list */}

                </div>
                
                <div className='flex'>
                <img src={remote} alt="..."
                    class="w-1/2 h-96 " />
                <p className='w-1/2 p-20 mt-10 text-2xl'>The ultimate support with online.zoho.com solution for your business</p>
                </div>
                <p class="mb-4 mt-10 text-base text-black font-semibold md:text-lg dark:text-black">What We Offer:</p>
                <ol class="text-base text-gray-500 list-decimal list-inside md:px-5 md:text-lg dark:text-gray-400">
                    <li className='text-base font-medium'><span className='text-black font-semibold'>Quality Assurance: </span> We prioritize quality and reliability in all our products, ensuring that every laptop in our inventory meets the highest standards.</li>
                    <li className='text-base font-medium'><span className='text-black font-semibold'>Wide Selection: </span> With a diverse range of brands, models, and configurations, you'll find the perfect workstation to meet your needs and preferences.</li>
                    <li className='text-base font-medium'><span className='text-black font-semibold'>Customization Options:  </span> With a wide range of customization options available, you can build the perfect workstation to meet your unique requirements.</li>
                    <li className='text-base font-medium'><span className='text-black font-semibold'>Warranty & Support services:  </span>We covered malfunctions under normal usage conditions. Our warranty ensures that your investment is protected, providing you with the assurance that we stand behind the quality and reliability of our products.</li>
                </ol>
            </div>
        </section>
    );
};

export default RemoteSupport;
