import React from 'react';
import { Link } from 'react-router-dom';
import About from '../../Images/Image2.jpg';

const Home2 = () => {
    return (
        <section className="py-10 lg:py-20 bg-white-100 font-poppins dark:bg-white-800">
            <div className="max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
                <div className="flex flex-wrap ">
                    <div className="w-full px-4 mb-0 lg:w-1/2 lg:mb-0 ">
                        <div className="lg:max-w-md">
                            <div className="px-4 pl-4 mb-6 border-l-4 border-blue-500">
                                <h1 className="mt-2 text-3xl font-semibold uppercase text-blue-900 dark:text-blue-900">
                                    About Us
                                </h1>
                            </div>
                            <p className="px-4 mb-5 text-base leading-7 text-gray-500 dark:text-gray-400">
                                <span className="uppercase text-black font-semibold">Computer Care Junction </span> that has been in business (All information Technology support and services.) company established since 2017.
                            </p>
                            <p className="px-4 mb-5 text-base leading-7 text-gray-500 dark:text-gray-400">
                                <span className="uppercase">CCJ </span> is an end to end IT solution provider that help business upgrade to modern IT infrastructure by offering high end solution in information infrastructure. Service solution, Hardware Testing & repairing services. IT rental, IT Sales, information security, Printing solution, networking infrastructure with Report & Certificate CCJ Team believes in provide best possible turnaround time to its customers and this, It operate through a massive network with several branches and own service center spared in different cities.
                            </p>
                            <p className="px-4 mb-5 text-base leading-7 text-gray-500 dark:text-gray-400">
                                At CC Junction, we ensure that our engineer understand the who and why behind not only our processes but also our value we offer our customer expertise to manage and maintain their process with effective solution .Our technical manpower is update with the latest innovation through various program. As a result we have large bank of engineers with certification of MSC IT .CCNA.MCSE Cisco and lotus. We have large pool of highly quality engineers who come with certification.
                            </p>
                            <p className="px-4 mb-5 text-base leading-7 text-gray-500 dark:text-gray-400">
                                Excellent IT supports service levels! At present, 95% of technical issues are resolved by our IT Support Specialists either by phone, remotely by Web Support or on site the same day.
                            </p>
                            <div className="flex flex-wrap items-center">
                                <Link to="/About">
                                    <button className="border border-blue-600 hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out px-4 py-2 rounded text-dark:blue-900 font-semibold mt-0">
                                        Explore More &rarr;
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
                        <img src={About} alt="..." className="relative z-40 object-cover w-full h-full rounded" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Home2;
